<template>
  <div>
 <img src="@/assets/solution/xiaoyuan.png" alt="">
  </div>
</template>

<script>
export default {
  name: "file-manage",
};
</script>

<style scoped>
div{
    text-align: center;
}
img{
    width: 1200px;
}
</style>